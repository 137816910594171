import React from "react"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      <Header />
      <div className="mt-[60.98px] lg:ml-72 lg:mt-0 flex-grow">{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
