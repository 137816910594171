import React from "react"

const Footer = () => {
  return (
    <footer className="lg:ml-72 bg-black text-white">
      <div className="p-6 text-center">
        Copyright &copy; {new Date().getFullYear()} BESHAUS. All Rights
        Reserved.
      </div>
    </footer>
  )
}

export default Footer
