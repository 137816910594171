import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaYoutube, FaFacebookF, FaInstagram } from "react-icons/fa"
import { MenuIcon, ArrowRightIcon } from "@heroicons/react/outline"
import { Transition } from "@headlessui/react"

const menuItems = [
  {
    label: "Home",
    to: "/",
  },
  {
    label: "About Us",
    to: "/about-us",
  },
  {
    label: "Services",
    to: "/services",
  },
  {
    label: "Projects",
    to: "/projects",
  },
  {
    label: "News",
    to: "/news",
    hidden: true,
  },
  {
    label: "Blogs",
    to: "/blogs",
  },
  {
    label: "Contacts",
    to: "/contacts",
  },
].filter(({ hidden }) => !hidden)

const socialLinks = [
  {
    icon: <FaYoutube className="w-4 h-4" />,
    href: "https://www.youtube.com/channel/UCe1l_fUGnP19Jbc4kv-PseQ",
  },
  {
    icon: <FaFacebookF className="w-4 h-4" />,
    href: "https://www.facebook.com/Beshaus.as",
  },
  {
    icon: <FaInstagram className="w-4 h-4" />,
    href: "https://www.instagram.com/beshaus.as/",
  },
]

const Header = () => {
  const [showHorizontalMenu, setShowHorizontalMenu] = useState(false)

  const toggleHorizontalMenu = () => {
    setShowHorizontalMenu(showHorizontalMenu => !showHorizontalMenu)
  }

  return (
    <header className="relative text-white">
      <div className="fixed top-0 left-0 z-[2] w-full bg-black lg:hidden shadow-lg">
        <div className="flex items-center">
          <div className="flex-grow">
            <div className="w-64 p-2">
              <StaticImage
                src="../assets/images/logo.png"
                className="h-full"
                quality="100"
                placeholder="blurred"
                alt="logo"
              />
            </div>
          </div>
          <div className="mr-6">
            <MenuIcon
              className="w-8 h-8 cursor-pointer"
              onClick={toggleHorizontalMenu}
            />
          </div>
        </div>
      </div>
      <Transition show={showHorizontalMenu}>
        <Transition.Child
          appear={true}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed w-screen h-screen top-0 left-0 z-[2] bg-black bg-opacity-70 overlay-cursor"
          onClick={toggleHorizontalMenu}
        ></Transition.Child>
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          className="fixed w-80 h-screen top-0 right-0 z-[2] bg-[#2a2a2a] p-8"
        >
          <div>
            <ArrowRightIcon
              className="w-8 h-8 cursor-pointer"
              onClick={toggleHorizontalMenu}
            />
          </div>
          <nav className="mt-10">
            <ul className="uppercase">
              {menuItems.map(({ label, to }, index) => (
                <li
                  key={`horizontal-menu-item-${index}`}
                  className="py-4 border-b border-opacity-20"
                >
                  <Link to={to}>{label}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </Transition.Child>
      </Transition>

      <div className="fixed top-0 left-0 h-full w-72 hidden lg:block">
        <div className="absolute top-0 left-0 h-full">
          <StaticImage
            src="../assets/images/vertical-menu-background.jpg"
            className="h-full"
            quality="100"
            placeholder="blurred"
            objectPosition="20% 50%"
            alt="vertical menu background"
          />
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-75"></div>

        <div className="relative w-full h-full">
          <div className="absolute top-0 left-0 w-full flex justify-center p-2">
            <StaticImage
              src="../assets/images/logo.png"
              className="h-full"
              quality="100"
              placeholder="blurred"
              alt="logo"
            />
          </div>

          <nav className="relative w-full h-full flex justify-center items-center text-center">
            <ul className="space-y-8 uppercase">
              {menuItems.map(({ label, to }, index) => (
                <li key={`vertical-menu-item-${index}`}>
                  <Link
                    className="relative after:transition-all after:duration-500 after:ease-in-out after:absolute after:left-0 after:-bottom-0.5 after:bg-white after:h-0.5 hover:after:w-full after:w-0"
                    activeClassName="after:!w-full"
                    to={to}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <div className="absolute bottom-12 left-0 w-full">
            <div className="flex space-x-5 justify-center">
              {socialLinks.map(({ icon, href }, index) => (
                <a
                  key={`verticle-menu-social-link-${index}`}
                  className="w-8 h-8 rounded-full border border-white flex justify-center items-center transition-colors duration-200 hover:border-[#444] hover:bg-[#444]"
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
